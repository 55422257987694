import Http from "@/services/Http";
export default {
  getUser() {
    return Http().post("user/get");
  },
  updatePassword(data) {
    return Http().post("password/update", data);
  },
  sendActivationLink(data) {
    return Http().post("reset/activation/link", data);
  },
  verifyEmail(data) {
    return Http().post("verify/email", data);
  },
  updateUser(data) {
    return Http().post("profile/update", data);
  },
  updateProfileImage(data) {
    return Http().post("profile/upload-image", data);
  },
  updateAcademicDetails(data) {
    return Http().post("academic/details/update", data);
  },
  getPrivilege() {
    return Http().post("privilege");
  }
};
