<template>
  <div class="profile-content">
    <div class="user-infobox">
      <div class="_user-image">
        <img :src="user.image" alt="" />
        <div class="image-edit">
          <div class="dummy-btn">
            <img src="../assets/Camera.svg" alt="" />
            <p>Change</p>
            <input type="file" @change="uploadImage($event)" />
          </div>
        </div>
      </div>
      <div class="user-contents">
        <h5>{{ user.first_name }} {{ user.last_name }}</h5>
        <div class="achieve-text">
          <img src="../assets/winner-icon.svg" alt="" />
          {{ user.admission_number }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-6">
        <div class="_personal-infowrapper contain-box">
          <div class="__header">
            <h4>Personal information</h4>
            <button class="btn cta-small" @click="personalReadOnly = false">
              edit
            </button>
          </div>
          <div class="__body">
            <form @submit.prevent="editUser">
              <div class="mb-3 form-row">
                <div class="col-md-12">
                  <label for="firstName" class="form-label">First Name</label>
                  <input
                    type="text"
                    v-model="firstName"
                    class="form-control"
                    id="firstName"
                    :readonly="personalReadOnly == true"
                  />
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.firstName.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <label for="lastName" class="form-label">Last Name</label>
                  <input
                    type="text"
                    v-model="lastName"
                    class="form-control"
                    id="lastName"
                    :readonly="personalReadOnly == true"
                  />
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.lastName.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                  type="text"
                  v-model="email"
                  class="form-control"
                  id="email-id"
                  :readonly="personalReadOnly == true"
                />
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.email.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">Phone</label>
                <input
                  type="text"
                  v-model="phone"
                  class="form-control"
                  id="phone"
                  :readonly="personalReadOnly == true"
                />
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.phone.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="mb-3">
                <label for="gender" class="form-label">Gender</label>
                <select
                  class="form-control"
                  v-model="gender"
                  id="gender"
                  :readonly="personalReadOnly == true"
                >
                  <option value="">Select Gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Others</option>
                </select>
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.gender.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="text-end">
                <input
                  type="submit"
                  class="btn cta-primary"
                  :disabled="profileUpdateLoader == true"
                  value="Update"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="_personal-infowrapper contain-box">
          <div class="__header">
            <h4>Academic Details</h4>
            <button class="btn cta-small" @click="academicReadOnly = false">
              edit
            </button>
          </div>
          <div class="__body">
            <form @submit.prevent="updateAcademicDetails">
              <div class="mb-3 form-row">
                <div class="col-md-12">
                  <label for="school_name" class="form-label"
                    >School Name</label
                  >
                  <input
                    type="text"
                    v-model="schoolName"
                    class="form-control"
                    id="school_name"
                    :readonly="academicReadOnly == true"
                  />
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.schoolName.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="standard" class="form-label">Standard</label>
                <select
                  class="form-control"
                  v-model="standard"
                  id="standard"
                  :readonly="academicReadOnly == true"
                >
                  <option value="">Select Standard</option>
                  <option
                    v-for="(standard, standardIndex) in standards"
                    :key="standardIndex"
                    :value="standard.id"
                  >
                    {{ standard.title }}
                  </option>
                </select>
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.standard.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div cclass="mb-3">
                <label for="parent_name" class="form-label">Parents Name</label>
                <input
                  type="text"
                  v-model="parentName"
                  class="form-control"
                  id="parent_name"
                  :readonly="academicReadOnly == true"
                />
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.parentName.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

              <div cclass="mb-3">
                <label for="parents_number" class="form-label"
                  >Parents Number</label
                >
                <input
                  type="text"
                  v-model="parentNumber"
                  class="form-control"
                  id="parents_number"
                  :readonly="academicReadOnly == true"
                />
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.parentNumber.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="mb-3">
                <label for="address" class="form-label">Address</label>
                <textarea
                  class="form-control"
                  v-model="address"
                  id="address"
                  :readonly="academicReadOnly == true"
                ></textarea>
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.schoolName.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="text-end">
                <input
                  type="submit"
                  class="btn cta-primary"
                  :disabled="academicDetailsUpdateLoader == true"
                  value="Update"
                  :readonly="academicReadOnly == true"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-12">
        <div class="contain-box">
          <div class="__header">
            <h4>Recent participation</h4>
          </div>
          <div class="__body">
            <div class="mini-card-wrapper">
              <circular-loader
                v-if="recentParticipationLoader == true"
                height="100"
              ></circular-loader>
              <div
                class="mini-card"
                v-for="(participation,
                participationIndex) in recentParticipation"
                :key="participationIndex"
              >
                <p>
                  <img src="../assets/calender-icon.svg" alt="" />{{
                    participation.start_time
                  }}
                </p>
                <h6>{{ participation.subject }}</h6>
                <!--<p>{{ participation.duration }}</p>
                 <ul class="list-unstyled thumblist">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li>
                    <a href="#">+23</a>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import UserService from "@/services/UserService";
import HomeService from "@/services/HomeService";

import ProgramService from "@/services/ProgramService";
import errorLog from "@/errorLog";

import CircularLoader from "@/components/loaders/CircularLoader";
import {
  required,
  helpers,
  email,
  numeric,
  minLength,
  maxLength
} from "@vuelidate/validators";
export default {
  name: "ProfileTab",
  components: { CircularLoader },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  created() {
    this.getStandards();

    this.getRecentParticipation();
  },
  data() {
    let user = JSON.parse(this.$store.getters["studentAuth/user"]);
    return {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phone: user.phone,
      profileUpdateLoader: false,
      academicDetailsUpdateLoader: false,
      gender: user.gender,
      standard: user.standard_id,
      schoolName: user.school_name,
      standards: [],
      parentName: user.parent_name,
      parentNumber: user.parent_contact_number,
      address: user.address,
      exams: [],
      assignments: [],
      recentParticipation: [],
      recentParticipationLoader: true,
      personalReadOnly: true,
      academicReadOnly: true
    };
  },
  computed: {
    user() {
      let user = JSON.parse(this.$store.getters["studentAuth/user"]);
      return user;
    }
  },
  validations() {
    return {
      firstName: {
        required: helpers.withMessage("Please enter first name", required)
      },
      lastName: {
        required: helpers.withMessage("Please enter last name", required)
      },
      gender: {
        required: helpers.withMessage("Please select gender", required)
      },
      email: {
        required: helpers.withMessage("Please enter email address", required),
        email: helpers.withMessage("Please enter valid email address", email)
      },
      phone: {
        required: helpers.withMessage("Please enter phone number", required),
        numeric: helpers.withMessage("Please enter valid number", numeric),
        maxLength: maxLength(15),
        minLength: minLength(6)
      },
      schoolName: {
        required: helpers.withMessage("Please select school name", required)
      },
      standard: {
        required: helpers.withMessage("Please select standard", required)
      },
      parentName: {
        required: helpers.withMessage("Please enter parent name", required)
      },
      parentNumber: {
        required: helpers.withMessage(
          "Please enter parent phone number",
          required
        )
      },
      address: {
        required: helpers.withMessage("Please enter address", required)
      }
    };
  },
  methods: {
    editUser: function() {
      this.v$.firstName.$touch();
      this.v$.lastName.$touch();
      this.v$.email.$touch();
      this.v$.phone.$touch();
      this.v$.gender.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.profileUpdateLoader = true;
      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        gender: this.gender
      };
      UserService.updateUser(data)
        .then(result => {
          this.profileUpdateLoader = false;
          this.$store.dispatch(
            "studentAuth/updateUser",
            JSON.stringify(result.data.student)
          );
          this.$toast.success("Profile updated ");
          this.personalReadOnly = true;
        })
        .catch(error => {
          this.profileUpdateLoader = false;
          errorLog.log(error);
        });
    },
    updateAcademicDetails: function() {
      this.v$.schoolName.$touch();
      this.v$.standard.$touch();
      this.v$.parentName.$touch();
      this.v$.parentNumber.$touch();
      this.v$.address.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.academicDetailsUpdateLoader = true;
      const data = {
        school_name: this.schoolName,
        standard_id: this.standard,
        parent_name: this.parentName,
        parent_contact_number: this.parentNumber,
        address: this.address
      };
      UserService.updateAcademicDetails(data)
        .then(result => {
          if (result.data.status == "success") {
            this.academicDetailsUpdateLoader = false;
            this.$store.dispatch(
              "studentAuth/updateUser",
              JSON.stringify(result.data.student)
            );
            this.academicReadOnly = true;
            this.$toast.success("Profile updated ");
          }
        })
        .catch(error => {
          this.academicDetailsUpdateLoader = false;
          errorLog.log(error);
        });
    },
    async uploadImage(event) {
      const formData = new FormData();
      formData.append("image", event.target.files[0]);
      await UserService.updateProfileImage(formData)
        .then(result => {
          this.$store.dispatch(
            "studentAuth/updateUser",
            JSON.stringify(result.data)
          );
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    async getStandards() {
      await HomeService.getStandardList()
        .then(result => {
          this.standards = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },

    getRecentParticipation: function() {
      ProgramService.getRecentParticipation()
        .then(result => {
          this.recentParticipationLoader = false;
          this.recentParticipation = result.data.data;
        })
        .catch(error => {
          this.recentParticipationLoader = false;
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/profile-tab.scss";
</style>
